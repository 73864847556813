<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col cols="12">
          <div >{{ $t('main.calculator_headline_inquiry') }}</div>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">

          <v-row>
            <v-col cols="12" md="6" class="flex-wrap">
              <span class="subtitle-2 text-uppercase">{{$t('main.route')}}</span><br>
              <span class="caption grey--text">{{$t('main.from')}}</span> {{ search.from.cityZip }}<br>
              <span class="caption grey--text">{{$t('main.to')}}</span> {{ search.to.cityZip }}<br>
              <span class="caption grey--text">{{$t('main.distance')}}</span> {{ Math.round(search.mapDistance) }} km
            </v-col>
            <v-col cols="12" md="6" class="flex-wrap">
              <span class="subtitle-2 text-uppercase">{{$t('main.loading')}}</span><br>
              <span class="caption grey--text">{{$t('main.length')}}</span> {{ $utils.format.toGermanFloat(search.freight.length) }} m<br>
              <span class="caption grey--text">{{$t('main.width')}}</span> {{ $utils.format.toGermanFloat(search.freight.width) }} m<br>
              <span class="caption grey--text">{{$t('main.height')}}</span> {{ $utils.format.toGermanFloat(search.freight.height) }} m<br>
              <span class="caption grey--text">{{$t('main.weight')}}</span> {{ $utils.format.toGermanFloat(search.freight.weight, 0) }} kg
            </v-col>
            <v-col cols="12">
              <v-divider />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" class="flex-wrap">
              <span class="subtitle-2 text-uppercase">{{$t('main.pickup_and_delivery')}}</span><br>
              <span class="caption grey--text">{{$t('main.type')}}</span> {{ $t('addons.' + $_.snakeCase(inquiry.pickupDelivery.executionTime.name)) }} <br>
              <span class="caption grey--text">{{$t('main.pickup')}} {{$t('main.from_time')}}</span> {{ startDE }}<br>
              <span class="caption grey--text">{{$t('main.delivery')}} {{$t('main.until')}}</span> {{ endDE }}
            </v-col>
            <v-col cols="12" md="6" class="flex-wrap">
              <span class="subtitle-2 text-uppercase">{{$t('main.time_window')}}</span><br>
              <span class="caption grey--text">{{$t('main.pickup')}}</span> {{ $t('addons.' + $_.snakeCase(inquiry.pickupDelivery.pickupExecutionTimewindow.name))}} / {{ inquiry.pickupDelivery.pickupTime }} {{$t('main.h')}}<br>
              <span class="caption grey--text">{{$t('main.delivery')}}</span> {{ $t('addons.' + $_.snakeCase(inquiry.pickupDelivery.deliveryExecutionTimewindow.name)) }} / {{ inquiry.pickupDelivery.deliveryTime }} {{$t('main.h')}}
            </v-col>
            <v-col cols="12">
              <v-divider />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" class="flex-wrap">
              <span class="subtitle-2 text-uppercase">{{$t('main.invoice_reference')}}</span><br>
              {{ inquiry.billingNotice || '-' }}
            </v-col>
            <v-col cols="12" md="6" class="flex-wrap">
              <span class="subtitle-2 text-uppercase">{{$t('main.supplementary_insurance')}}</span><br>
              <span v-if="!inquiry.insurance">{{$t('texts[1]')}}</span>
              <span v-else><span class="caption grey--text">{{$t('main.value_of_goods')}} (max.):</span> {{$utils.format.toGermanPriceFloat(insuranceValue, 0)}}</span>
            </v-col>
            <v-col cols="12">
              <v-divider />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" class="flex-wrap">
              <span class="subtitle-2 text-uppercase">{{ $_.upperFirst($t('main.cargo')) }}</span><br>
              {{ inquiry.cargo || '-' }}
            </v-col>
            <v-col cols="12" md="6" class="flex-wrap">
              <span class="subtitle-2 text-uppercase">{{ $_.upperFirst($t('main.additional_services')) }}</span><br>
              <div v-for="(addon, index) in addons" :key="'addon_' + index">
                <v-icon color="success" left>mdi-check</v-icon><span v-text="$t('addons.' + $_.snakeCase(addon.name))" />
              </div>
            </v-col>
            <v-col cols="12">
              <v-divider />
            </v-col>
          </v-row>

        </v-col>
      </v-row>

      <v-row justify="center">
        <v-expansion-panels   class="mx-3 my-6" >
          <v-expansion-panel v-if="this.inquiry.notice">
            <v-expansion-panel-header>{{ $_.upperFirst($t('main.notice')) }}</v-expansion-panel-header>
            <v-expansion-panel-content>{{ this.inquiry.notice }}</v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>{{ $_.upperFirst($t('main.billing_address')) }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="4" md="2">
                  {{ $t('main.company') }}:
                </v-col>
                <v-col cols="8" md="4">
                  {{ addresses.billing.company }}
                </v-col>
                <v-col cols="4" md="2">
                  {{ $t('main.vat_id') }}:
                </v-col>
                <v-col cols="8" md="4">
                  {{ addresses.billing.ust || '-' }}
                </v-col>
                <v-col cols="4" md="2">
                  {{ $t('main.firstname') }} {{ $t('main.lastname') }}:
                </v-col>
                <v-col cols="8" md="4">
                  {{ addresses.billing.surname }} {{ addresses.billing.lastname }}
                </v-col>
                <v-col cols="4" md="2">
                  {{ $tc('main.address', 1) }}:
                </v-col>
                <v-col cols="8" md="4">
                  {{ addresses.billing.address }}
                </v-col>
                <v-col cols="4" md="2">
                  {{ $t('main.zip') }}/{{ $t('main.city') }}:
                </v-col>
                <v-col cols="8" md="4">
                  {{ addresses.billing.zip }} {{ addresses.billing.city }}
                </v-col>
                <v-col cols="4" md="2">
                  {{ $t('main.email') }}:
                </v-col>
                <v-col cols="8" md="4">
                  {{ addresses.billing.email }}
                </v-col>
                <v-col cols="4" md="2">
                  {{ $t('main.phone') }}:
                </v-col>
                <v-col cols="8" md="4">
                  {{ addresses.billing.phone }}
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if=" addresses.sender.address">
            <v-expansion-panel-header>{{ $_.upperFirst($t('main.pickup')) }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="4">
                  {{ $t('main.company') }}:
                </v-col>
                <v-col cols="8">
                  {{ addresses.sender.company }}
                </v-col>
                <v-col cols="4">
                  {{ $t('main.contact_person') }}:
                </v-col>
                <v-col cols="8">
                  {{ addresses.sender.contact_person }}
                </v-col>
                <v-col cols="4">
                  {{ $t('main.phone') }}:
                </v-col>
                <v-col cols="8">
                  {{ addresses.sender.phone }}
                </v-col>
                <v-col cols="4">
                  {{ $t('main.address') }}:
                </v-col>
                <v-col cols="8">
                  {{ addresses.sender.address }}
                </v-col>
                <v-col cols="4">
                  {{ $t('main.zip') }}/{{ $t('main.city') }}:
                </v-col>
                <v-col cols="8">
                  {{ addresses.sender.country + ' ' + addresses.sender.zip + ' ' + addresses.sender.city }}
                </v-col>
              </v-row>

            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="addresses.receiver.address">
            <v-expansion-panel-header>{{ $_.upperFirst($t('main.receiver')) }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="4">
                  {{ $t('main.company') }}:
                </v-col>
                <v-col cols="8">
                  {{ addresses.receiver.company }}
                </v-col>
                <v-col cols="4">
                  {{ $t('main.contact_person') }}:
                </v-col>
                <v-col cols="8">
                  {{ addresses.receiver.contact_person }}
                </v-col>
                <v-col cols="4">
                  {{ $t('main.phone') }}:
                </v-col>
                <v-col cols="8">
                  {{ addresses.receiver.phone }}
                </v-col>
                <v-col cols="4">
                  {{ $t('main.address') }}:
                </v-col>
                <v-col cols="8">
                  {{ addresses.receiver.address }}
                </v-col>
                <v-col cols="4">
                  {{ $t('main.zip') }}/{{ $t('main.city') }}:
                </v-col>
                <v-col cols="8">
                  {{ addresses.receiver.country + ' ' + addresses.receiver.zip + ' ' + addresses.receiver.city }}
                </v-col>
              </v-row>

            </v-expansion-panel-content>
          </v-expansion-panel>

        </v-expansion-panels>
      </v-row>

    </v-card-text>
  </v-card>

</template>

<script>

import moment from 'moment'

export default {
  name: 'InquiryOverview',
  components: {},
  props: {
    step: {
      type: Number,
      default: 5
    }
  },
  data () {
    return {
      search: this.$store.getters.search,
      inquiry: this.$store.getters.inquiry
    }
  },
  computed: {
    addresses () {
      return this.inquiry.addresses ? this.inquiry.addresses : { billing: {}, sender: {}, receiver: {} }
    },
    startDE () {
      return this.inquiry.pickupDelivery.pickup ? moment(this.inquiry.pickupDelivery.pickup).format('DD.MM.YYYY') : null
    },
    endDE () {
      return this.inquiry.pickupDelivery.delivery ? moment(this.inquiry.pickupDelivery.delivery).format('DD.MM.YYYY') : null
    },
    addons() {
      if(!this.inquiry.addons) {
        return []
      } else {
        let a = []
        let b = this.$store.getters.addons
        this.$_.forEach(this.inquiry.addons, id => {
            let addon = this.$_.find(b, item => {
              return item.id_addon == id
            })
          a.push(addon)
        })
        return a
      }
    },
    insuranceValue() {
      return this.$store.getters.inquiry.insurance ? parseInt(this.$store.getters.inquiry.insurance) : 0
    },
  },
  mounted () {

  },
  methods: {
    saveToStorage () {
      //
    },
    init () {
      //
    }
  }
}
</script>

