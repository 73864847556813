<template>
  <div>
    <v-btn v-if="!isActive" block class="d-flex justify-space-between" @click="clear();isActive=true;">
      <v-icon class="mr-2">mdi-cloud-search-outline</v-icon>
      {{$t('texts[4]')}}</v-btn>
    <v-autocomplete
        v-if="isActive"
        v-model="model"
        @click="clear"
        @change="change"
        :items="items"
        :loading="isLoading"
        :search-input.sync="search"
        :filter="customFilter"
        color="white"
        no-filter
        item-value="id"
        item-text="description"
        :label="$t('main.company') + ' / ' + $t('main.location')"
        :hint="$utils.translate('Bitte geben Sie mindestens 3 Zeichen ein.', false, true, 'rules')"
        :placeholder="$t('main.search')"
       :name="$http.uniqid('frm')"
        autocomplete="new-password"
        return-object
    ></v-autocomplete>
  </div>
</template>

<script>
export default {
  name: 'AutocompleteAddress',
  props: {
    type: {
      type: String,
      default: 'billing'
    }
  },
  data () {
    return {
      isActive: false,
      address: {},
      model: {},
      items: [],
      isLoading: false,
      search: '',
      getPlacesDebounced: this.$_.debounce(this.doSearch, 500)
    }
  },
  watch: {
    search (n) {
      if (this.isLoading) return
      this.isLoading = true
      if (n && n.length > 2) {
        this._doSearch()
      } else {
        this.isLoading = false
      }

    }
  },
  mounted () {
  },
  methods: {
    _doSearch () {
      this.getPlacesDebounced()
    },
    doSearch () {
      if (this.model.description === this.search && this.search.length > 2) {
        this.isLoading = false
        return false
      }

      this.$http.ext.get('get_places', { params: { input: this.search, locale: this.$i18n.locale } })
      .then(result => {
        result = result.data.result
        this.items = []
        this.isLoading = false
        if (result.status === 'OK') {
          this.$_.forEach(result.predictions, item => {
            this.items.push({ description: item.description, id: item.place_id, meta: item })
          })
        }
        if (result.status !== 'OK' && this.search) {
          this.items.push({
            description: this.search, id: 0
          })
        }
      })

    },
    change () {
      this.$http.ext.get('get_places_detail', { params: { place_id: this.model.id } }).then(result => {
        if (result.status === 200) {
          result = result.data.result.result
          this.address = this.buildAddressFromGoogleResult(result)
          this.search = this.model.description = this.model.description.split(',')[0]
          this.$emit('company-selected', this.address, '' + this.search)
          this.isActive = false
        }
      })
      .catch(() => {

      })
    },
    buildAddressFromGoogleResult(result) {
      let r = result.address_components
      let a = {
        phone: result.international_phone_number
      }
      this.$_.forEach(r, item => {
        if(item.types.indexOf('street_number') > -1) {
          a.street_number = item.short_name
        }
        if(item.types.indexOf('route') > -1) {
          a.street = item.long_name
        }
        if(item.types.indexOf('locality') > -1) {
          a.city = item.long_name
        }
        if(item.types.indexOf('postal_code') > -1) {
          a.zip = item.long_name
        }
        if (item.types.indexOf('country') > -1) {
          a.country = item.short_name
        }
      })
      a.address = a.street + ' ' + a.street_number
      return a
    },
    clear () {
      this.search = ''
      this.$emit('company-cleared')
    },
    customFilter (item, queryText) {
      // eslint-disable-next-line no-useless-escape
      let search = queryText.split(/[\,\ ]/)
      let c = 0
      const description = item.description.toLowerCase()
      this.$_.forEach(search, item => {
        item = item.toLowerCase().trim()
        if (description.indexOf(item) > -1 || item === '') {
          c++
        }
      })
      return c === search.length
    }
  }
}
</script>

