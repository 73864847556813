<template>
    <v-app id="app" :style="isPlugin? 'background-color:transparent;min-height:inherit !important;':''">

        <v-container fluid>

            <v-app-bar v-if="!isPlugin" app flat color="rgba(0,0,0,0)" hide-on-scroll>
                <v-row v-if="computedIsInited">
                    <v-col cols="12" class="d-flex justify-end">
                        <logo-image :has-link="true" class="mr-4"/>
                    </v-col>
                </v-row>
            </v-app-bar>

            <v-main class="my-8 py-8 d-flex justify-center">
                <AppIndex v-if="computedIsInited"/>
            </v-main>

            <v-footer v-if="!isPlugin" app padless inset class="my-0 py-0">
                <v-row v-if="computedIsInited && $vuetify.breakpoint.smAndDown">

                    <v-col cols="12" class="d-flex align-center justify-space-between px-4">
                        <span @click="openTPUrl" style="cursor: pointer;line-height:1">
                          <logo-wzft-white v-if="this.$vuetify.theme.isDark" style="width: 90%;max-width:200px"></logo-wzft-white>
                          <logo-wzft-black v-else style="width: 100%;max-width:200px"></logo-wzft-black>
                        </span>
                        <span v-if="this.$store.getters.instance.link_imprint" style="cursor:pointer" class="overline" @click="openImprint">{{ $t('main.imprint') }}</span>

<!--
                        <div v-for="(lang,country) in countries" :key="country">
                            <span @click="$root.$i18n.locale = lang" style="cursor:pointer" :class="$vuetify.breakpoint.smAndDown ?'pl-1' : 'px-1'">{{country}}</span>
                        </div>-->

                        <v-select  solo dense flat single-line class="my-0 py-0 lang" v-model="$root.$i18n.locale" @change="changeLocale" :items="_countriesForSelect"  style="max-width:80px" />

                    </v-col>


                </v-row>
                <v-row v-if="computedIsInited && $vuetify.breakpoint.mdAndUp">
                    <v-col  cols="6" sm="6" style="line-height:1" class="py-2 my-0 pl-6 d-flex align-center justify-start">
                        <span @click="openTPUrl" style="cursor: pointer;line-height:1">
                          <logo-wzft-white v-if="this.$vuetify.theme.isDark" style="width: 90%;max-width:200px"></logo-wzft-white>
                          <logo-wzft-black v-else style="width: 100%;max-width:200px"></logo-wzft-black>
                        </span>
                    </v-col>
                    <v-col cols="6" sm="6" class="d-flex align-center justify-end pr-6 ma-0">
                        <span v-if="this.$store.getters.instance.link_imprint" style="cursor:pointer" class="px-1 overline pr-4" @click="openImprint">{{ $t('main.imprint') }}</span>
                        <div v-for="(country, lang) in countries" :key="country">
                            <span @click="$root.$i18n.locale = lang" style="cursor:pointer" :class="$vuetify.breakpoint.smAndDown ?'pl-1' : 'px-1'"><country-flag :country='country' :size="$vuetify.breakpoint.smAndDown ? 'small' : 'normal'"/></span>
                        </div>
                    </v-col>
                </v-row>
            </v-footer>

        </v-container>

        <dialog-loading v-if="!isInited" :dialog="true"/>
        <dialog-app-error :dialog-app-error="dialogAppError"/>
        <v-snackbar v-model="snack" :timeout="snackTimeout" :color="snackColor">
            <div class="d-flex justify-space-between">
                {{ snackText }}
                <v-icon small dark class="ml-2" @click="snack = false">mdi-close</v-icon>
            </div>
        </v-snackbar>

    </v-app>
</template>

<script>
import DialogAppError from '@/_shared/components/DialogAppError'
import DialogLoading from '@/_shared/components/DialogLoading'
import LogoWzftWhite from "@/_shared/svg/LogoWZFTWhite";
import LogoWzftBlack from "@/_shared/svg/LogoWZFTBlack";
import LogoImage from "@/_shared/components/LogoImage";

import AppIndex from "@/anfrage/views/AppIndex";
import CountryFlag from 'vue-country-flag'


export default {
    name      : 'App',
    components: {
        AppIndex,
        LogoImage,
        DialogLoading,
        DialogAppError,
        LogoWzftWhite,
        LogoWzftBlack,
        CountryFlag
    },
    data() {
        return {
            snack         : false,
            snackColor    : '',
            snackText     : '',
            snackTimeout  : 5000,
            dialogAppError: false,
            isInited      : false,
            countries     : {
                'de': 'de',
                'en': 'gb',
                'nl': 'nl',
                'dk': 'dk',
                'pl': 'pl'
            },
            lang: 'de',
        }
    },
    computed: {
        appError() {
            return this.$root.appError
        },
        notice() {
            return this.$root.notice;
        },
        computedIsInited() {
            return this.isInited
        },
        isPlugin() {
            if (sessionStorage.getItem('isPlugin')) {
                return true
            }
            return false
        },
        langs() {
            let l = null;
            if (this.$store.getters.langs) {
                l = this.$store.getters.langs.map(lang => {
                    return lang.lang
                })
            }

            return l
        },
        _countriesForSelect() {
            return Object.keys(this.countries)
        }
    },
    watch   : {
        notice(n) {
            if (n.type === 'error') {
                this.snackTimeout = 0
            } else {
                this.snackTimeout = 5000
            }
            this.snackColor = n.type
            this.snack = true
            this.snackText = n.msg
        },
        'appError.show'(n) {
            this.dialogAppError = n
        }
    },
    mounted() {
        this.lang = this.$root.$i18n.locale
        this.$http.init(this)
        this.$utils.init(this)
        this.init()
    },
    methods: {
        init() {
            this.$http.etc.get('get_token', {params: {app: 'anfrage'}})
                .then(result => {
                    result = this.$http.filterApiCollection(result.data.result)
                    sessionStorage.setItem('token', result.token)
                    this.$store.commit('setConfig', result.config)
                    this.$store.commit('setToken', result.token)
                    this.initApp()
                })
                .catch(() => {
                    sessionStorage.clear()
                    window.location = process.env.VUE_APP_URL
                })
        },
        initApp() {
            this.$http.etc.get('app_init', {params: {app: 'anfrage'}})
                .then(result => {
                    result = this.$http.filterApiCollection(result.data.result)
                    result.instance.countries = result.instance.countries_iso.split('|')
                    this.$store.commit('setToken', result.token)
                    this.$store.commit('setConfig', result.config)
                    this.$store.commit('setInstance', result.instance)
                    this.$store.dispatch('getSessionDataFromLocaleStorage')
                    sessionStorage.setItem('locale', result.instance.lang_iso)
                    this.setStyle(result)
                    this.getGoogleScript()
                    this.getInstanceAddons()

                })
                .catch(() => {
                    sessionStorage.clear()
                    window.location = process.env.VUE_APP_URL
                })
        },
        getInstanceAddons() {
            if (!this.$store.getters.addons) {
                this.$http.api.get('vw_instance_addons', {params: {id_instance: this.$store.getters.instance.id}})
                    .then(result => {
                        result = this.$http.filterApiCollection(result)
                        this.$store.commit('setAddons', result)
                        this.setLang()
                    })
            } else {
                this.setLang()
            }
        },
        getGoogleScript() {
            this.$http.ext.get('get_google_maps_script_src')
                .then(result => {
                    this.pMap = new Promise((resolve) => {
                        window['GoogleMapsInit'] = resolve
                        let GMap = document.createElement('script')
                        GMap.setAttribute('src', result.data.result + '&callback=GoogleMapsInit&region=DE&libraries=visualization')
                        document.head.appendChild(GMap)
                    })
                    if (this.pMap) {
                        this.pMap.then(() => {
                            this.$root.googleMapsIsLoaded = true
                        })
                    }
                })
        },
        setStyle(result) {
            let style = this.$_.isObject(JSON.parse(result.instance.style)) ? JSON.parse(result.instance.style) : Object.assign({}, result.config.defaultStyle)
            this.$utils.setStyle(style, this.$vuetify)
        },
        setLang() {
            /* I18 */
            if (!this.$store.getters.langs) {
                this.$http.etc.get('get_lang')
                    .then(result => {
                        result = result.data.result
                        result.map(row => {
                            row.data = JSON.parse(row.data)
                            row.id = parseInt(row.id)
                        })
                        this.$store.commit('setLangs', result)
                        this.setI18Data()
                    })
            } else {
                this.setI18Data()
            }
        },
        setI18Data() {
            this.$store.getters.langs.map(row => {
                if (row.lang == 'en') {
                    const ad = row.data.addons
                    let a = this.$store.getters.addons

                    this.$_.forEach(a, item => {
                        const n = this.$_.snakeCase(item.name)
                        if (ad[n]) {
                            item.name_en = ad[n]
                        }
                    })
                    this.$store.commit('setAddons', a)
                }

                this.$i18n.setLocaleMessage(row.lang, row.data)
                if (sessionStorage.getItem('locale')) {
                    this.$i18n.locale = sessionStorage.getItem('locale')
                }
            })
            this.isInited = true
        },
        changeLocale() {
            sessionStorage.setItem('locale', this.$root.$i18n.locale)
        },
        openTPUrl() {
            window.open('https://transportpreis.digital/', '_blank')
        },
        openImprint() {
            window.open(this.$store.getters.instance.link_imprint, '_blank')
        },
    }
}
</script>

<style >
 .lang .v-text-field__details {
     display: none !important;
 }
</style>