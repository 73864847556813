import Vue from 'vue'
import App from './App.vue'

import rules from '@/_shared/etc/rules'
import utils from '@/_shared/etc/utils'
import http from '@/_shared/etc/http'
import VueLodash from '@/_shared/plugins/vue-lodash'
import vuetify from '@/_shared/plugins/vuetify'
import i18n from '@/_shared/i18n'

import mixins from '@/_shared/mixins'
import directives from '@/_shared/directives'

//import router from './router'
import store from './store'

require('@/_shared/css/global.css')

Vue.use(VueLodash)
Vue.use(rules)
Vue.use(utils)
Vue.use(http)

Vue.config.productionTip = false
Vue.mixin(mixins)



new Vue({
  vuetify,
  /*router,*/
  store,
  i18n,
  directives: {
    directives,
  },
  data: () => ({
    appError: {},
    appInfo: {},
    notice: {},
    googleMapsIsLoaded: false,
  }),
  mounted () {
    this.appInfo = this.$utils.getAppInfo()
    document.title = 'TRANSPORTANFRAGE.online ' + process.env.VUE_APP_TITLE_APPEND
    if(process.env.VUE_APP_MODE === 'development') {
      sessionStorage.setItem('token', 'e89b3df85af4bc06c201983467b022a9128af29c153f24f8666bcf95872409d2')
    }
  },
  render: h => h(App)
}).$mount('#app')
