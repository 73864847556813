<template>
    <v-row>
      <v-col cols="12">
        <v-stepper v-model="step" vertical class="elevation-0" non-linear>

          <v-stepper-step step="1" :complete="locationsSet" @click="step=1">
            {{$t('main.loading_and_unloading_point').toUpperCase()}}
          </v-stepper-step>
          <v-stepper-content step="1">
            <Location @complete="locationsAreSet" v-if="!locationIsReloading" @uncomplete="locationsAreUnset" @blur="locationsBlur">></Location>
          </v-stepper-content>

          <v-stepper-step step="2" :complete="freightSet" @click="step=2">
            {{$t('main.freight_description').toUpperCase()}}
          </v-stepper-step>
          <v-stepper-content step="2">
            <Freight @complete="freightIsSet" @uncomplete="freightIsUnset" @blur="freightBlur"></Freight>
          </v-stepper-content>

          <v-stepper-step step="3" :complete="loadingSet" @click="step=3">
            {{$t('main.loading').toUpperCase()}}
          </v-stepper-step>
          <v-stepper-content step="3">
            <v-row>
              <v-col cols="12">
                <Loading @complete="loadingIsSet" @uncomplete="loadingIsUnset"></Loading>
              </v-col>
            </v-row>
          </v-stepper-content>

        </v-stepper>
      </v-col>
    </v-row>
</template>

<script>
import Freight from '@/anfrage/components/input/Freight'
import Loading from '@/anfrage/components/input/Loading'
import Location from '@/anfrage/components/input/Location'


export default {
  name: 'Input',
  components: { Loading, Freight, Location },
  data () {
    return {
      isLoading: false,
      searchIsLoading: false,
      locationIsReloading: false,
      step: 1,
      locationsSet: false,
      zoneSet: false,
      freightSet: false,
      loadingSet: false,
      current: {
        from: {},
        to: {},
        mapDistance: '',
        freight: {
          weight: 0,
          length: 0,
          height: 0
        },
        loading: 0,
        unloading: 0
      },
      mapDistance: '',
      debouncedSearch: null,
    }
  },
  watch: {
    locationsSet(n) {
      if(n) {
        this.$emit('locationsSet', this.current)
      }
    },
    allSet(n) {
      if(n) {
        this.$emit('inputSet', this.current)
      }
      if(!n) {
        this.$emit('inputUnset')
      }
    }
  },
  computed: {
    allSet () {
      return this.loadingSet && this.locationsSet && this.freightSet
    },
  },
  mounted () {

  },
  methods: {
    locationsAreSet ({ locations }) {
      this.current.from = locations.from
      this.current.to = locations.to
      this.current.zone = locations.zone
      this.current.mapDistance = locations.mapDistance
      this.current.mapDistanceText = locations.mapDistanceText
      this.locationsSet = true

    },
    locationsAreUnset () {
      this.locationsSet = false
    },
    freightIsSet ({ freight }) {
      this.current.freight = freight
      this.freightSet = true

    },
    freightIsUnset () {
      this.freightSet = false
    },
    loadingIsSet ({ loading, unloading, loadingIsUnknown, unloadingIsUnknown }) {
      this.current.loading = loading
      this.current.unloading = unloading
      this.current.loadingIsUnknown = loadingIsUnknown ? true : false
      this.current.unloadingIsUnknown = unloadingIsUnknown ? true : false
      this.loadingSet = true

    },
    loadingIsUnset () {
      this.loadingSet = false
    },
    locationsBlur () {
      if (this.locationsSet && !this.allSet) {
        this.step = 2
      }
    },
    freightBlur () {
      if (this.freightSet && !this.allSet) {
        this.step = 3
      }
    },
    nextStep () {
      this.step++
    },
    prevStep () {
      this.step--
    },
  }
}
</script>
