<template>
  <v-form>
    <v-row>
      <v-col cols="12" class="subtitle-1">
        {{$t('main.additional_services')}}
      </v-col>
      <v-col cols="12">
        <v-expansion-panels>
          <v-expansion-panel v-for="(value, key) in $store.getters.config.addonGroups" :key="key">
            <v-expansion-panel-header v-text="$_.capitalize($t('addonGroupsExtended.' + key))"/>
            <v-expansion-panel-content>
              <v-row >
                <v-col v-for="(addon, index) in getAddonsInGroup(key)" :key="'addon_name_' + index" cols="12" md="6" lg="4" class="py-0 my-0 d-flex align-center">
                  <v-switch v-blur v-model="addon.is_active" inset @change="change(addon)" />
                  <span class="caption" v-text="$t('addons.' + $_.snakeCase(addon.name))"></span>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: 'InquiryAddons',
  props: {
    step: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      addonsSelected: []
    }
  },

  computed: {

  },
  mounted () {
      this.addonsSelected = this.$store.getters.inquiry.addons || []
  },
  methods: {
    init () {
    },
    getAddonsInGroup(group) {
      let g = this.$_.filter(this.$store.getters.addons, addon => {
        return addon.to_group === group
      }) || []
      this.$_.forEach(g, item => {
        if(this.addonsSelected.indexOf(item.id_addon) > -1 ) {
          item.is_active = 1
        }
      })
      return g
    },
    saveToStore() {
      //
    },
    change(e) {
      if(e.is_active) {
        this.addonsSelected.push(e.id_addon)
      } else {
        this.addonsSelected = this.$_.filter(this.addonsSelected, item => {
          return item != e.id_addon
        })
      }
      this.$store.commit('setInquiryAddons', this.addonsSelected)
    }
  }
}
</script>

