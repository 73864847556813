<template>
  <div class="d-flex justify-center">

    <v-card :max-width="isPlugin ? '100%' : 1000" :min-width="isPlugin ? '100%' : ''" :loading="isLoading">

      <v-card-title :class="this.$vuetify.theme.isDark ? 'primary darken-3' : 'primary'">
        <v-row>
          <v-col cols="12" class="py-0 d-flex justify-space-between align-center">
            <span style="display: inline-block;max-width:70vw" class="caption grey--text text--lighten-3 text-truncate"
                  v-text="routeText"/>
            <v-btn v-if="isPlugin" small outlined @click="changeLang()">
              <flags :country="langCountry" style="width:20px; height: 15px"/>
            </v-btn>
          </v-col>
          <v-col cols="12" class="py-0"><span class="headline white--text">{{ $t('main.calculator_title') }}</span>
          </v-col>
        </v-row>
      </v-card-title>


      <v-card-text :class="page=='map' ? 'pa-0' : ''">
        <Input v-if="page==='input' || page==='map'" v-show="page==='input'" @locationsSet="onInputLocationSet"
               @inputSet="onInputAllSet" @inputUnset="onInputUnset" ref="input"/>
        <GMapRoute v-show="page==='map'" ref="gmap" :width="appWidth" :height="appHeight"/>
        <Inquiry v-if="page==='inquiry'" ref="inquiry" @disableNextButton="onDisableNextButton" @finish="onFinish"/>
      </v-card-text>

      <v-card-actions v-show="!isLoading">

        <v-row class="px-2" dense>
          <v-col cols=12 class="d-flex justify-space-between align-center">

            <v-btn v-blur ripple v-if="(page=='input' || page=='inquiry') && !isSaved" :disabled="prevButtonIsDisabled"
                   @click="prevStep()">
              <v-icon class="mr-0 mr-sm-2">mdi-chevron-left</v-icon>
              <span class="hidden-xs-only">{{ $t('main.back') }}</span>
            </v-btn>

            <v-btn v-blur ripple v-if="page=='input'" :disabled="!locationsSet" @click="openMap()">
              <v-icon class="mr-0 mr-sm-2">mdi-map</v-icon>
              <span class="hidden-xs-only">{{ $t('main.map') }}</span>
            </v-btn>

            <v-btn v-blur ripple block v-if="page=='map'" @click="closeMap">
              <v-icon class="mr-0 mr-sm-2"> mdi-close</v-icon>
              <span class="hidden-xs-only"> {{ $t('main.map') }} {{ $t('main.close') }}</span>
            </v-btn>

            <v-btn v-blur ripple v-if="(page=='input' || page=='inquiry')  && !isSaved && !saveButtonIsEnabled"
                   :disabled="nextButtonIsDisabled" @click="nextStep">
              <span class="hidden-xs-only">{{ $t('main.next') }}</span>
              <v-icon class="ml-0 ml-sm-2">mdi-chevron-right</v-icon>
            </v-btn>

            <v-btn v-blur ripple class="primary" v-if="page=='inquiry' && saveButtonIsEnabled" @click="save">
              <span>{{ $t('main.calculator_send_query') }}</span>
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>

            <v-btn v-ripple v-blur v-if="isSaved" @click="newQuery()">
              <v-icon left>mdi-chevron-left</v-icon>
              <span>{{ $t('main.calculator_new_query') }}</span>
            </v-btn>

          </v-col>
        </v-row>


      </v-card-actions>
    </v-card>

  </div>
</template>

<script>


import Input from "@/anfrage/components/Input";
import GMapRoute from "@/anfrage/components/GMapRoute";
import Inquiry from "@/anfrage/components/Inquiry";
import Flags from "@/_shared/svg/Flags";

export default {
  name: 'AppIndex',
  components: {Flags, Inquiry, GMapRoute, Input},
  data() {
    return {
      isLoading: false,
      page: 'input',
      subpage: '',
      step: 1,
      current: {},
      locationsSet: false,
      inputAllSet: false,
      isFinished: false,
      isSaved: false,
      disableNextButton: false,
      appWidth: 1000,
      appHeight: 700
    }
  },
  computed: {
    routeText() {
      return !this.locationsSet ? '' : ((this.current.mapDistance ? Math.round(this.current.mapDistance) + ' ' + this.$t('main.km') + ' ' + this.$t('main.from') : this.$t('main.from')) + ' ' + this.current.from.cityZip + ' ' + this.$t('main.to') + ' ' + this.current.to.cityZip)
    },
    nextButtonIsDisabled() {
      if (this.disableNextButton) {
        return true
      }
      if (this.page === 'input') {
        if (this.step === 3 && !this.inputAllSet) {
          return true
        }
      }
      return false
    },
    prevButtonIsDisabled() {
      return this.page === 'input' && this.step === 1
    },
    saveButtonIsEnabled() {
      return this.isFinished && !this.isSaved
    },
    langCountry() {
      return this.$i18n.locale === 'de' ? 'GB' : 'DE'
    },
    isPlugin() {
      if(sessionStorage.getItem('isPlugin')) {
        return true
      }
      return false
    },
  },
  beforeMount() {

  },
  mounted() {
    this.$utils.scrollToTop()

  },
  methods: {
    /* Event Listeners */
    onInputLocationSet(e) {
      this.locationsSet = true
      this.current.from = e.from
      this.current.to = e.to
      this.current.mapDistance = parseFloat(e.mapDistance)

    },
    onInputAllSet(data) {
      this.locationsSet = true
      this.inputAllSet = true
      this.current = data
    },
    onInputUnset() {
      this.inputAllSet = false
    },
    onDisableNextButton(n) {
      this.disableNextButton = n
    },
    onFinish() {
      this.isFinished = true
    },

    /* Button Functions */
    nextStep() {
      this.step++
      if (this.page == 'input') {
        if (this.inputAllSet) {
          this.step = 1
          this.$store.commit('searchSetDistance', this.current.mapDistance)
          this.page = 'inquiry'
          return
        }
        this.$refs['input'].nextStep()
      }
      if (this.page == 'inquiry') {
        this.$utils.scrollToTop()
        this.$refs['inquiry'].nextStep()
      }
    },
    prevStep() {
      this.isFinished = false
      this.step--
      if (this.page == 'input') {
        this.$refs['input'].prevStep()
      }
      if (this.page == 'inquiry') {
        if (this.step === 0) {
          this.page = 'input'
          //this.step = 1
          return
        }
        this.$refs['inquiry'].prevStep()
      }
    },
    openMap() {
      this.appWidth = this.$refs.input.$el.clientWidth
      this.appHeight = this.$refs.input.$el.clientHeight
      this.page = 'map'
      this.$refs['gmap'].setRoute(this.current)
    },
    closeMap() {
      this.page = 'input'
    },
    changeLang() {
      if (this.$i18n.locale === 'de') {
        this.$i18n.locale = 'en'
      } else {
        this.$i18n.locale = 'de'
      }
    },
    save() {
      this.isLoading = true
      this.$http.etc.post('save_order', {
        id_instance: this.$store.getters.instance.id,
        search: this.$store.getters.search,
        inquiry: this.$store.getters.inquiry,
        lang: this.$root.$i18n.locale
      })
          .then(() => {
            this.doNoticeSuccess(this.$t('prompts.send_success'))
            this.isLoading = false
            this.isSaved = true
          })
          .catch(() => {
            this.isLoading = false
          })
    },
    newQuery() {
      this.$store.dispatch('clearSessionData')
      this.page = 'input'
      this.step = 1
      this.isFinished = false
      this.disableNextButton = false
      this.isSaved = false
      this.locationsSet = false
      this.inputAllSet = false
    }
  }
}
</script>
