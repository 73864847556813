<template>
  <v-row>

    <v-col cols="12">
      <v-stepper v-model="step" flat>
        <v-stepper-header>
          <template v-for="(n, i) in computedSteps">
            <v-stepper-step :key="`${i + 1}-step`" :complete="n.completed" :step="i + 1" class=" ">
              <span v-if="$vuetify.breakpoint.smAndUp" class="subtitle-2" v-text="$_.upperFirst(n.title)"/>
            </v-stepper-step>
            <v-divider v-if="i < computedSteps.length - 1" :key="`${i + 1}-div`"></v-divider>
          </template>
        </v-stepper-header>
      </v-stepper>
    </v-col>

    <v-col cols="12">
      <v-expand-transition>
        <component :is="computedComponents[step - 1]" @complete="onComplete" @uncomplete="onUncomplete" ref="actualComponent"/>
      </v-expand-transition>
    </v-col>

  </v-row>
</template>

<script>
import InquiryAddons from '@/anfrage/components/inquiry/InquiryAddons'
import InquiryAddresses from '@/anfrage/components/inquiry/InquiryAddresses'
import InquiryInsurance from '@/anfrage/components/inquiry/InquiryInsurance'
import InquiryLoadingTimes from '@/anfrage/components/inquiry/InquiryLoadingTimes'
import InquiryNotice from '@/anfrage/components/inquiry/InquiryNotice'
import InquiryOverview from '@/anfrage/components/inquiry/InquiryOverview'


export default {
  name: 'Inquiry',
  components: {InquiryOverview, InquiryInsurance, InquiryNotice, InquiryAddresses, InquiryAddons, InquiryLoadingTimes},
  data() {
    return {
      step: 1,
      isLoading: false,
      dialogFinish: false,
      tabs: [],
      stepsCompleted: [],
    }
  },
  computed: {
    lastStep() {
      return this.computedSteps.length
    },
    computedSteps() {
      let t = []
      this.tabs.map(tab => {
        if (!tab.hide) {
          t.push(tab)
        }
      })
      return t
    },
    computedComponents() {
      let t = []
      this.tabs.map(tab => {
        if (!tab.hide) {
          t.push(tab.comp)
        }
      })
      return t
    },
    nextButtonIsDisabled: {
      cache: false,
      get() {
        return this.stepsCompleted[this.step - 1] ? false : true
      }
    },
    isFinished() {
      return this.step === this.lastStep
    }
  },
  watch: {
    nextButtonIsDisabled(n) {
      this.$emit('disableNextButton', n)
    },
    isFinished(n) {
      if(n) {
        this.$emit('finish')
      }
    }
  },
  beforeMount() {
    /* Tabs */
    let tabs_hidden = null;
    if (this.$store.getters.instance.tabs_hidden) {
      tabs_hidden = JSON.parse(this.$store.getters.instance.tabs_hidden)
    }
    let tabs = []
    let steps = 1;
    let h = false
    tabs.push({
      title: this.$tc('main.time', 2),
      completed: false,
      hide: h,
      step: steps,
      optional: true,
      comp: InquiryLoadingTimes
    })
    steps++
    try {
      h = !(!tabs_hidden['addons'] || !tabs_hidden)
    } catch (e) {
      h = false
    }
    //h = !(!tabs_hidden['addons'] || !tabs_hidden)
    tabs.push({
      title: this.$tc('main.service', 2),
      completed: false,
      hide: h,
      step: steps,
      optional: true,
      comp: InquiryAddons
    })
    steps += h ? 0 : 1
    h = false
    tabs.push({
      title: this.$tc('main.address', 2),
      completed: false,
      hide: h,
      step: steps,
      optional: false,
      comp: InquiryAddresses
    })
    steps++
    try {
      h = !(!tabs_hidden['loading'] || !tabs_hidden)
    } catch (e) {
      h = false
    }
    //h = !(!tabs_hidden['loading'] || !tabs_hidden)
    tabs.push({
      title: this.$t('main.cargo'),
      completed: false,
      hide: h,
      step: steps,
      optional: true,
      comp: InquiryNotice
    })
    steps += h ? 0 : 1
    try {
      h = !(!tabs_hidden['insurance'] || !tabs_hidden)
    } catch (e) {
      h = false
    }
    //h = !(!tabs_hidden['insurance'] || !tabs_hidden)
    tabs.push({
      title: this.$t('main.insurance'),
      completed: false,
      hide: h,
      step: steps,
      optional: true,
      comp: InquiryInsurance
    })
    steps += h ? 0 : 1
    h = false
    tabs.push({
      title: this.$t('main.confirmation'),
      completed: false,
      hide: h,
      step: steps,
      optional: false,
      comp: InquiryOverview
    })
    this.stepsCompleted = tabs.map(tab => {
      return tab.optional
    })
    this.tabs = tabs
  },
  mounted() {
    this.search = this.$store.getters.search

  },
  methods: {
    onComplete() {
      this.$emit('disableNextButton', false)
      this.stepsCompleted[this.step - 1] = true
    },
    onUncomplete() {
      this.$emit('disableNextButton', true)
      this.stepsCompleted[this.step - 1] = false
    },
    nextStep() {
      this.$refs['actualComponent'].saveToStore()
      this.step++

    },
    prevStep() {
      if (this.step === 1) {
        this.$router.back()
        return
      }
      this.step--
    },
  }
}
</script>
