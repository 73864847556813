<template>
  <div>
    <v-form ref="form" v-model="isValid">
      <div class="title mb-1 d-flex justify-space-between">{{ headline }}
        <v-icon v-if="isValid && isActive">mdi-check</v-icon>
      </div>
      <v-divider class="mb-1"/>
      <div v-if="!this.isActive">
        <v-row>
          <v-col cols="12">
            <v-btn color="secondary" block class="d-flex justify-space-between" @click="isActive=true">
              {{$t('texts[7]')}}
              <v-icon right>mdi-arrow-bottom-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div v-if="this.isActive">
        <v-row v-if="(current.type==='Gewerblich' && this.type === 'billing') || this.type !== 'billing'">
          <v-col cols="12">
            <AutocompleteAddress :type="this.type" @company-cleared="companyCleared" @company-selected="companySelected"></AutocompleteAddress>
          </v-col>
        </v-row>

        <v-row v-if="this.type != 'billing' && this.billingAddress">
          <v-col cols="12">
            <v-btn color="secondary" block class="d-flex justify-space-between" @click="copyBillingAddress()">
              <v-icon class="mr-2">mdi-content-copy</v-icon>
              {{ textBillingAddress }}
            </v-btn>
          </v-col>
        </v-row>


        <v-row v-if="this.type === 'billing'">
          <v-col cols="12">
            <v-select :items="['Gewerblich', 'Privat']" v-model="current.type"/>
          </v-col>
        </v-row>


        <v-row v-if="current.type==='Gewerblich' && this.type === 'billing'">
          <v-col cols="12">
            <v-text-field :label="$t('main.vat_id')" name='UST' v-model="current.ust"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field :label="$t('main.company') + '*'" v-model="current.company" name='Firma' :rules="[$rules.required]"></v-text-field>
          </v-col>
          <v-col cols="12" v-if="this.type==='billing'">
            <v-text-field :label="$t('main.firstname') + '*'" name="Vorname" v-model="current.surname" :rules="[$rules.required]"></v-text-field>
          </v-col>
          <v-col cols="12" v-if="this.type==='billing'">
            <v-text-field :label="$t('main.lastname') + '*'" name='Nachname' v-model="current.lastname" :rules="[$rules.required]"></v-text-field>
          </v-col>
          <v-col cols="12" v-if="this.type!='billing'">
            <v-text-field :label="$t('main.phone') " name="Telefon" v-model="current.phone"></v-text-field>
          </v-col>
          <v-col cols="12" v-if="this.type!='billing'">
            <v-text-field :label="$t('main.contact_person')" v-model="current.contact_person"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field :label="$tc('main.address', 1) + '*'" name="Strasse" v-model="current.address" :rules="[$rules.required]"></v-text-field>
          </v-col>
          <v-col cols="12" xs="4">
            <v-text-field :label="$t('main.zip') + '*'"  name="PLZ" v-model="current.zip" :rules="[$rules.required]"></v-text-field>
          </v-col>
          <v-col cols="12" xs="8">
            <v-text-field :label="$t('main.city') + '*'" name="Ort" v-model="current.city" :rules="[$rules.required]"></v-text-field>
          </v-col>
          <v-col cols="12" v-if="this.type==='billing'">
            <v-text-field :label="$t('main.email') + '*'" name="Email" v-model="current.email" :rules="[$rules.required, $rules.email]"></v-text-field>
          </v-col>
          <v-col cols="12" v-if="this.type==='billing'">
            <v-text-field :label="$t('main.phone') " name="Telefon" v-model="current.phone"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select :items="$store.getters.instance.countries" :label="$t('main.country') + ' (ISO)'" v-model="current.country"/>
          </v-col>
        </v-row>
      </div>


    </v-form>

  </div>
</template>

<script>
import AutocompleteAddress from '@/anfrage/components/AutocompleteAddress'

export default {
  name: 'AddressInput',
  components: { AutocompleteAddress },
  props: {
    type: {
      type: String,
      default: 'billing'
    },
    billingAddress: {
      type: Object,
      default: null
    }

  },
  data () {
    return {
      current: {
        type: 'Gewerblich',
        company: '',
        ust: '',
        contact_person: '',
        surname: '',
        lastname: '',
        address: '',
        zip: '',
        city: '',
        email: '',
        phone: '',
        country: 'DE',
        is_save: false
      },
      currentReset: null,
      headlines: {
        billing: 'Rechnungsadresse',
        sender: 'Absenderadresse',
        receiver: 'Empfängeradresse'
      },
      isValid: false,
      search: {},
      isActive: false
    }
  },
  watch: {

    isValid: {
      handler (n) {
        if (n && this.current.address) {
          this.$emit('complete', this.type)
        } else {
          this.$emit('uncomplete', this.type)
        }
      }
    },
    current: {
      handler () {
        this.$emit('change', this.type, this.current)
      },
      deep: true
    }
  },
  computed: {
    headline () {
      const h = {
        billing: this.$t('main.billing_address'),
        sender: this.$t('main.sender_address'),
        receiver: this.$t('main.receiver_address')
      }
      return h[this.type]
    },
    textBillingAddress () {
      if (this.$vuetify.breakpoint.xl) {
        return this.$t('main.adopt_billing_address')
      } else {
        return this.$t('main.billing_address')
      }
    }
  },
  mounted () {
    const inquiry = this.$store.getters.inquiry
    this.isActive = this.type === 'billing'
    if (inquiry && inquiry.addresses) {
      if (inquiry.addresses[this.type]) {
        if (inquiry.addresses[this.type].address) {
          this.current = inquiry.addresses[this.type]
          this.$emit('complete', this.type)
        }
      }
    }
    if (this.type != 'billing' && !this.current.city) {
      const data = this.type === 'sender' ? this.$store.getters.search.from : this.$store.getters.search.to
      if (this.$_.isObject(data)) {
        this.current.city = data.city
        this.current.zip = data.zip
      }

    }
  },
  methods: {
    companySelected (address, company) {
      this.current.address = address.street + ' ' + address.street_number
      this.current.zip = address.zip
      this.current.city = address.city
      this.current.phone = address.phone
      this.current.company = company
      this.current.country = address.country
    },
    companyCleared () {
      this.current.address = ''
      this.current.zip = ''
      this.current.city = ''
      this.current.phone = ''
      this.current.company = ''
    },
    copyBillingAddress () {
      this.current.company = this.billingAddress.company
      this.current.address = this.billingAddress.address
      this.current.contact_person = this.billingAddress.surname + ' ' + this.billingAddress.lastname
      this.current.phone = this.billingAddress.phone
      this.current.city = this.billingAddress.city
      this.current.zip = this.billingAddress.zip
      this.current.country = this.billingAddress.country
    }
  }
}
</script>


